import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: "cb-explorer-palmira.firebaseapp.com",
  projectId: "cb-explorer-palmira",
  storageBucket: "cb-explorer-palmira.appspot.com",
  messagingSenderId: "371217810019",
  appId: process.env.VUE_APP_FIREBASE_ID,
  measurementId: "G-5WFFWX9XK3",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export { auth };
