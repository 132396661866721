import axios from "@/services/axiosSet.js";

export default {
  registerStudent({ registration }) {
    return axios.post(`registro/`, registration);
  },
};
// export default {
//   registerStudent({ registration }) {
//     axios.post(`registro/`, registration).catch((error) => {
//       console.error(error);
//     });
//   },
// };
