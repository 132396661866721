import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.VUE_APP_API_KEY_AWS,
  },
});
export default instance;
