import Vue from "vue";
import Vuex from "vuex";
import axios from "@/services/axiosSet";
import registration from "./registration.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    institutionInfo: {},
  },
  mutations: {
    setData(state, data) {
      state.institutionInfo = data;
      localStorage.setItem("registerPalmiras", JSON.stringify(data));
    },
  },
  actions: {
    async getInstitutionInfo({ commit }, uuid) {
      await axios
        .get(`registro/${uuid}/`)
        .then((response) => {
          commit("setData", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getLocalStorageFromVuex({ commit }, data) {
      commit("setData", data);
    },
  },
  getters: {
    getUid(storeState) {
      return storeState.institutionInfo.consulted_id;
    },
    institutionInfo: (state) => state.institutionInfo,
  },
  modules: {
    registration,
  },
});
