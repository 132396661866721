<template>
  <div class="login">
    <layout-header :isLogin="true" :showLogout="true" />
    <section class="w-full d-flex flex-column align-center custom-padding">
      <h3 class="mt-6 max-w login__title">
        {{ $t("main.text") }}
      </h3>
      <router-link :to="'registration'" class="btn btn-primary mt-10">
        {{ $t("main.new-inscription") }}
      </router-link>
      <button
        type="button"
        @click="getFile"
        :disabled="loading"
        class="btn btn-primary mt-5"
      >
        <span v-t="'main.download'" v-if="!loading" />
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </button>
      <label v-if="errorFile" class="text-center mt-5 custom-label-error">
        {{ errorFile }}
      </label>
    </section>
  </div>
</template>

<script>
import LayoutHeader from "../components/LayoutHeader";
import { mapGetters } from "vuex";
import axios from "@/services/axiosSet";

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      errorFile: "",
    };
  },
  components: {
    LayoutHeader,
  },
  methods: {
    async getFile() {
      this.loading = true;
      await axios
        .get(`registro/list/${this.uid}/`, {
          headers: {
            Accept: "application/zip",
          },
          responseType: `arraybuffer`,
        })
        .then((response) => {
          if (response.status === 204)
            this.errorFile = "No hay registros de estudiantes";
          else if (response.status === 404)
            this.errorFile = "Director o establecimientos no encontrados";
          else {
            let blob = new Blob([response.data], { type: "application/zip" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.uid}.zip`;
            link.click();
            link.remove();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },
  },
  computed: {
    ...mapGetters({
      uid: "getUid",
    }),
  },
};
</script>
