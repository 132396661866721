import registrationService from "@/services/registration";

export default {
  state: {
    registration: {},
  },

  getters: {
    registration: (state) => state.registration,
  },

  mutations: {
    setRegistration: (state, registration) => {
      state.registration = registration;
    },
  },

  actions: {
    setRegistration: ({ commit }, registration) => {
      registrationService.registerStudent(registration);
      commit("setRegistration", registration);
    },
  },

  namespaced: true,
};
