<template>
  <div>
    <registration-form />
  </div>
</template>

<script>
import RegistrationForm from "../components/RegistrationForm.vue";

export default {
  name: "Registration",

  components: {
    RegistrationForm,
  },
};
</script>
