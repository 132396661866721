<template>
  <div class="registration">
    <layout-header :showLogout="true" />
    <div
      class="registration__main"
      :class="{ 'registration__main--mobile': mobile }"
    >
      <router-link :to="'home'" class="btn btn-secondary btn-return">
        {{ $t("generic.return") }}
      </router-link>
      <h1 class="registration__main__title">
        {{ $t("registration.contact-details") }}
      </h1>
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <p class="ma-0">
            {{ $t("registration.firstName-parent") }}
          </p>
        </div>
        <input
          class="custom-input"
          type="text"
          v-model="registration.firstParentName"
        />
      </div>
      <label
        v-if="errorForm.firstParentName"
        class="custom-label-error"
        v-t="'registration.required'"
      />
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <p class="ma-0">
            {{ $t("registration.lastName-parent") }}
          </p>
        </div>
        <input v-model="registration.lastParentName" class="custom-input" />
      </div>
      <label
        v-if="errorForm.lastParentName"
        class="custom-label-error"
        v-t="'registration.required'"
      />
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <div class="registration__main__input__text__long">
            <strong
              class="
                registration__main__input__text__long
                registration__main__input__text__long--bold
              "
              >{{ $t("registration.text-document") }}</strong
            >
            {{ $t("registration.text-document2") }}
          </div>
        </div>
        <div class="registration__main__input__radio-btn">
          <div class="registration__main__input__radio-btn__div mb-2">
            <button
              class="
                registration__main__input__radio-btn__div__btn
                radio-btn radio-btn--rounded
                mr-2
              "
              @click="selectedNoIdentity()"
            >
              <div v-if="noIdentityNumber === true" class="dot" />
            </button>
            <p class="registration__main__input__radio-btn__div__text ma-0">
              {{ $t("registration.person-without-identity-number") }}
            </p>
          </div>
          <input
            v-if="!noIdentityNumber"
            v-model="registration.parentIdentityNumb"
            class="custom-input"
          />
        </div>

        <label
          v-if="errorForm.parentIdentityNumb"
          class="custom-label-error"
          v-t="'registration.required'"
        />
      </div>
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div v-if="!noIdentityNumber" class="registration__main__dot">*</div>
          <p v-if="!noIdentityNumber" class="ma-0">
            {{ $t("registration.repeat-your-identity-number") }}
          </p>
        </div>
        <input
          v-if="!noIdentityNumber"
          class="custom-input"
          v-model="registration.parentIdentityNumbValidation"
        />
      </div>
      <label
        v-if="errorForm.parentIdentityNumbValidation.required"
        class="custom-label-error"
        v-t="'registration.required'"
      />
      <label
        v-if="errorForm.parentIdentityNumbValidation.noMatch"
        class="custom-label-error"
        v-t="'registration.noMatch-identity-number'"
      />
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <p class="ma-0">
            {{ $t("registration.phone-number-parent") }}
          </p>
        </div>
        <div class="registration__main__input__div">
          <div style="width: 85px">
            <v-select
              disabled
              :label="showLabel ? label : ''"
              outlined
              background-color="white"
              @click="hideLabel"
              hide-selected
              height="41px"
              :dense="true"
              v-model="registration.regionNumb"
            >
            </v-select>
          </div>
          <input
            type="number"
            v-model="registration.phoneNumb"
            placeholder=" x x x x x x x x x"
            class="custom-input ml-2"
          />
        </div>
        <label
          v-if="errorForm.phoneNumb"
          class="custom-label-error"
          v-t="'registration.required'"
        />
      </div>
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <p class="ma-0">
            {{ $t("registration.optional-phone-number-parent") }}
          </p>
        </div>
        <div class="registration__main__input__div">
          <div style="width: 85px">
            <v-select
              disabled
              :label="showLabel ? label : ''"
              outlined
              background-color="white"
              @click="hideLabel"
              hide-selected
              height="41px"
              :dense="true"
              v-model="registration.regionNumb2"
            >
            </v-select>
          </div>
          <input
            type="number"
            class="custom-input ml-2"
            placeholder=" x x x x x x x x x"
            v-model="registration.phoneNumb2"
          />
        </div>
      </div>
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <p class="ma-0">
            {{ $t("registration.email-parent") }}
          </p>
        </div>
        <input
          class="custom-input"
          placeholder="nombre@mail.com"
          v-model="registration.parentEmail"
        />
      </div>
      <label
        v-if="errorForm.parentEmail"
        class="custom-label-error"
        v-t="'registration.not-email'"
      />
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <p class="ma-0">
            {{ $t("registration.address-of-parent") }}
          </p>
        </div>
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <p class="registration__main__input__text__subtitle ma-0">
            {{ $t("registration.street-or-avenue") }}
          </p>
        </div>
        <div>
          <input
            class="custom-input custom-input--up"
            v-model="registration.parentAddressName"
          />
        </div>
        <label
          v-if="errorForm.parentAddressName"
          class="custom-label-error"
          v-t="'registration.required'"
        />
        <div class="registration__main__input__text mt-5">
          <p class="registration__main__input__text__subtitle ma-0">
            {{ $t("registration.number-address") }}
          </p>
        </div>
        <input
          class="custom-input custom-input--up"
          v-model="registration.parentAddressNumber"
        />
        <div
          class="registration__main__input__text mt-3"
          style="flex-direction: column; align-items: start"
        >
          <p class="registration__main__input__text__subtitle ma-0">
            {{ $t("registration.municipality") }}
          </p>
          <p class="registration__main__input__text__subtitle ma-0">
            {{ $t("registration.department") }}
          </p>
          <p class="registration__main__input__text__subtitle ma-0">
            {{ $t("registration.country") }}
          </p>
        </div>
        <div class="registration__main__input">
          <div class="registration__main__input__text">
            <div class="registration__main__dot">*</div>
            <p class="ma-0">
              {{ $t("registration.fistName-student") }}
            </p>
          </div>
          <input class="custom-input" v-model="registration.studentFirstName" />
        </div>
        <label
          v-if="errorForm.studentFirstName"
          class="custom-label-error"
          v-t="'registration.required'"
        />
        <div class="registration__main__input">
          <div class="registration__main__input__text">
            <div class="registration__main__dot">*</div>
            <p class="ma-0">
              {{ $t("registration.lastName-student") }}
            </p>
          </div>
          <input class="custom-input" v-model="registration.studentLastName" />
        </div>
        <label
          v-if="errorForm.studentLastName"
          class="custom-label-error"
          v-t="'registration.required'"
        />
        <div class="registration__main__input">
          <div class="registration__main__input__text">
            <div class="registration__main__dot">*</div>
            <div class="registration__main__input__text ma-0">
              {{ $t("registration.students-civil-registration-number") }}
            </div>
          </div>
          <div class="registration__main__input__radio-btn">
            <div class="registration__main__input__radio-btn__div mb-2">
              <button
                class="
                  registration__main__input__radio-btn__div__btn
                  radio-btn radio-btn--rounded
                  mr-2
                "
                @click="selectedStudentoNoid"
              >
                <div v-if="studentNoIdentityNumber" class="dot" />
              </button>
              <p class="registration__main__input__radio-btn__div__text ma-0">
                {{
                  $t("registration.student-without-civil-registration-number")
                }}
              </p>
            </div>
            <input
              v-if="!studentNoIdentityNumber"
              v-model="registration.studentIdentityNumb"
              class="custom-input"
            />
          </div>
          <label
            v-if="errorForm.studentIdentityNumb"
            class="custom-label-error"
            v-t="'registration.required'"
          />
        </div>
      </div>
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <p class="ma-0">
            {{ $t("registration.institution") }}
          </p>
        </div>
        <div style="width: 300px">
          <v-select
            :label="registration.institution"
            :items="[0, 1]"
            outlined
            background-color="white"
            @click="hideLabel"
            hide-selected
            height="41px"
            :dense="true"
            v-model="registration.institution"
            disabled
          >
          </v-select>
        </div>
      </div>
      <label
        v-if="errorForm.institution"
        class="custom-label-error"
        v-t="'registration.required'"
      />
      <div class="registration__main__input registration__main__input--up">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <div>
            <div></div>
          </div>
          <p class="ma-0">
            {{ $t("registration.campus") }}
          </p>
        </div>
        <div style="width: 300px">
          <v-select
            :items="campusList"
            outlined
            background-color="white"
            @click="hideLabel"
            hide-selected
            height="41px"
            :dense="true"
            v-model="registration.campus"
          >
          </v-select>
        </div>
        <label
          v-if="errorForm.campus"
          class="custom-label-error -mt-5"
          v-t="'registration.required'"
        />
      </div>
      <div class="registration__main__input">
        <div class="registration__main__input__text">
          <div class="registration__main__dot">*</div>
          <p class="ma-0">
            {{ $t("registration.student-has-brother") }}
          </p>
        </div>
        <div class="registration__main__input__brothers">
          <div
            @click="hasBrothers(0)"
            class="registration__main__input__brothers__btn"
            :class="{
              'registration__main__input__brothers__btn--selected':
                selectedBrothers === 0,
            }"
          >
            {{ $t("registration.yes") }}
          </div>
          <div
            @click="hasBrothers(1)"
            class="registration__main__input__brothers__btn"
            :class="{
              'registration__main__input__brothers__btn--selected':
                selectedBrothers === 1,
            }"
          >
            {{ $t("registration.no") }}
          </div>
        </div>
      </div>
    </div>
    <div class="registration__contact">
      <p class="registration__contact__title">
        {{ $t("registration.contact-means") }}
      </p>
      <div class="registration__contact__options">
        <div v-for="(media, index) in contactMediaList" :key="index">
          <div class="d-flex">
            <div
              class="mt-2 mr-2 radio-btn"
              @click="selectedMediaContact(media)"
            >
              <div v-if="selectedMedia.includes(media)" class="dot" />
            </div>
            <div class="registration__contact__options__text">{{ media }}</div>
          </div>
        </div>
      </div>
      <!-- <button @click="sendData" class="btn btn-secondary mt-5">
        {{ $t("registration.inscribe") }}
      </button> -->
      <button type="button" class="btn btn-secondary mt-5" @click="openDialog">
        {{ $t("registration.inscribe") }}
      </button>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-text class="pt-10">
            {{ $t("modal.text") }}
          </v-card-text>
          <v-card-actions class="d-flex w-full justify-center align-center">
            <v-btn
              class="btn btn-secondary btn-modal"
              text
              @click="dialog = false"
            >
              {{ $t("generic.return") }}
            </v-btn>
            <v-btn class="btn btn-primary btn-modal" text @click="sendData">
              <span v-t="'modal.confirm'" v-if="!loading" />
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-alert
      v-if="showAlert"
      class="alert"
      :type="alert.success ? 'success' : 'error'"
      >{{ alert.text }}</v-alert
    >
  </div>
</template>

<script>
import LayoutHeader from "../components/LayoutHeader";
import { mapActions, mapGetters } from "vuex";
import axios from "@/services/axiosSet";

export default {
  name: "RegistrationForm",
  components: {
    LayoutHeader,
  },
  data() {
    return {
      showAlert: false,
      alert: {},
      dialog: false,
      loading: false,
      label: "+57",
      showLabel: true,
      selectedBrothers: 1,
      campusList: [],
      contactMediaList: [
        "E-mail/correo electrónico",
        "Teléfono llamada",
        "Teléfono SMS",
        "Teléfono WhatsApp",
      ],
      selectedMedia: [],
      noIdentityNumber: false,
      studentNoIdentityNumber: false,
      registration: {
        firstParentName: "",
        lastParentName: "",
        parentIdentityNumb: "",
        parentIdentityNumbValidation: "",
        regionNumb: "+57",
        phoneNumb: "",
        regionNumb2: "+57",
        phoneNumb2: "",
        parentEmail: "",
        parentAddressName: "",
        parentAddressNumber: "",
        studentFirstName: "",
        studentLastName: "",
        studentIdentityNumb: "",
        institution: "",
        campus: "",
        hasBrothers: false,
      },
      errorForm: {
        firstParentName: false,
        lastParentName: false,
        parentIdentityNumb: false,
        parentIdentityNumbValidation: { required: false, noMatch: false },
        regionNumb: false,
        phoneNumb: false,
        parentEmail: false,
        parentAddressName: false,
        parentAddressNumber: false,
        studentFirstName: false,
        studentLastName: false,
        studentIdentityNumb: false,
        institution: false,
        campus: false,
      },
    };
  },
  mounted() {
    const campusArray = [];
    if (this.institutionInfo) {
      this.registration.institution = this.institutionInfo.name;
    }
    this.institutionInfo.institutions[0].sedes.forEach((sede) => {
      campusArray.push(sede.nombre_sede);
    });
    this.campusList = campusArray;
  },
  computed: {
    ...mapGetters({
      institutionInfo: "institutionInfo",
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      setRegistration: "registration/setRegistration",
    }),
    hideLabel() {
      this.showLabel = false;
    },
    hasBrothers(numb) {
      if (numb !== this.selectedBrothers) {
        this.selectedBrothers = numb;
      }
      if (numb === 0) {
        this.hasBrothers === true;
      }
    },
    selectedNoIdentity() {
      if (this.noIdentityNumber === false) {
        return (this.noIdentityNumber = true);
      }
      return (this.noIdentityNumber = false);
    },
    selectedStudentoNoid() {
      if (this.studentNoIdentityNumber === false) {
        return (this.studentNoIdentityNumber = true);
      }
      return (this.studentNoIdentityNumber = false);
    },
    selectedMediaContact(media) {
      const newSelectedMedia = [];
      if (this.selectedMedia.includes(media) === false) {
        return this.selectedMedia.push(media);
      } else {
        this.selectedMedia.forEach((el) => {
          if (el !== media) {
            newSelectedMedia.push(el);
          }
        });
        return (this.selectedMedia = newSelectedMedia);
      }
    },

    sendData() {
      this.loading = true;
      const dniPresent = this.noIdentityNumber ? false : true;
      const studentDniPresent = this.studentNoIdentityNumber ? false : true;
      const parentFullNumber =
        this.registration.regionNumb + this.registration.phoneNumb;
      const parentAltNumber =
        this.registration.regionNumb2 + this.registration.phoneNumb2;
      const institutionInfo = this.institutionInfo;
      let campusId = "";
      institutionInfo.institutions[0].sedes.forEach((sede) => {
        if (sede.nombre_sede === this.registration.campus) {
          campusId = sede.codigo_sede;
        }
      });

      const registration = {
        parent_name: this.registration.firstParentName,
        parent_lastname: this.registration.lastParentName,
        parent_dni_present: dniPresent,
        parent_dni: this.registration.parentIdentityNumb,
        parent_contact_main_phone: parentFullNumber,
        parent_contact_alternate_phone: parentAltNumber,
        parent_address: {
          street_name: this.registration.parentAddressName,
          street_number: this.registration.parentAddressNumber || "",
        },
        parent_email: this.registration.parentEmail,
        student_name: this.registration.studentFirstName,
        student_lastname: this.registration.studentLastName,
        student_dni_present: studentDniPresent,
        student_dni: this.registration.studentIdentityNumb,
        establishment: {
          code_id: institutionInfo.institutions[0].id_institucion,
          name: institutionInfo.institutions[0].nombre_institucion,
        },
        site: {
          code_id: campusId,
          name: this.registration.campus,
        },
        student_siblings: this.registration.hasBrothers,
        contact_options: {
          email: this.selectedMedia.includes("E-mail/correo electrónico"),
          phone: this.selectedMedia.includes("Teléfono llamada"),
          sms: this.selectedMedia.includes("Teléfono SMS"),
          whatsapp: this.selectedMedia.includes("Teléfono WhatsApp"),
        },
        inscriptionDate: new Date(),
      };
      axios
        .post(`registro/`, registration)
        .then(() => {
          this.configAlert(true);
          this.loading = false;
          this.dialog = false;
          this.$router.push("/home");
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.dialog = false;
          this.configAlert(false);
        });
    },
    openDialog() {
      let isError = false;
      this.validateForm();
      for (const key in this.errorForm) {
        if (key === "parentIdentityNumbValidation") {
          if (
            this.errorForm[key].required === true ||
            this.errorForm[key].noMatch === true
          ) {
            isError = true;
          }
        } else if (this.errorForm[key] === true) {
          isError = true;
          break;
        }
      }
      this.dialog = isError ? false : true;
      if (isError) this.configAlert(false);
    },
    configAlert(success) {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
      if (success)
        return (this.alert = {
          success: true,
          text: "Estudiante Registrado",
        });
      return (this.alert = {
        success: false,
        text: "Faltan campos por rellenar",
      });
    },
    validateForm() {
      this.resetFormErrors();

      for (const key in this.errorForm) {
        if (key === "parentIdentityNumb") {
          const validate = this.validateEmpty(this.registration[key]);
          this.errorForm[key] = this.noIdentityNumber ? false : validate;
        } else if (key === "parentIdentityNumbValidation") {
          if (!this.noIdentityNumber) {
            if (
              this.registration[key] !== this.registration["parentIdentityNumb"]
            )
              this.errorForm[key].noMatch = true;
            else
              this.errorForm[key].required = this.validateEmpty(
                this.registration[key]
              );
          }
        } else if (key === "studentIdentityNumb") {
          const validate = this.validateEmpty(this.registration[key]);
          this.errorForm[key] = this.studentNoIdentityNumber ? false : validate;
        } else if (key === "parentEmail") {
          this.errorForm[key] =
            this.registration[key] !== "" &&
            !this.validEmail(this.registration[key])
              ? true
              : false;
        } else this.errorForm[key] = this.validateEmpty(this.registration[key]);
      }
    },
    resetFormErrors() {
      this.errorForm = {
        firstParentName: false,
        lastParentName: false,
        parentIdentityNumb: false,
        parentIdentityNumbValidation: { required: false, noMatch: false },
        regionNumb: false,
        phoneNumb: false,
        regionNumb2: false,
        parentEmail: false,
        parentAddressName: false,
        parentAddressNumber: false,
        studentFirstName: false,
        studentLastName: false,
        studentIdentityNumb: false,
        institution: false,
        campus: false,
      };
    },
    validateEmpty(value) {
      if (value === "") return true;
      return false;
    },
    validEmail: function (email) {
      let re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>
