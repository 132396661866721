<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { auth } from "@/services/firebase";

export default {
  name: "App",
  data: () => ({
    //
  }),
  mounted() {
    const dataLocalStorage = localStorage.getItem("registerPalmiras");
    const routeActive = this.$router.currentRoute.path;

    auth.onAuthStateChanged((user) => {
      if (!user) {
        if (routeActive !== "/login") this.$router.push("/login");
        return;
      }
      if (dataLocalStorage) {
        this.$store.dispatch(
          "getLocalStorageFromVuex",
          JSON.parse(dataLocalStorage)
        );
      }
    });
  },
};
</script>
