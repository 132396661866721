<template>
  <section class="d-flex flex-column align-center login">
    <LayoutHeader :isLogin="true" :showLogout="false" />
    <div class="custom-padding max-w mt-3">
      <h3 class="login__title" v-t="'login.title-inscription'" />
      <h4 class="login__subtitle mt-3" v-t="'login.subtitle-inscription'" />
      <form
        class="d-flex flex-column align-center mt-10"
        @submit.prevent="submitLogin"
      >
        <!-- email -->
        <label class="custom-label w-full">
          <span class="asterisk">*</span>
          {{ $t("login.email") }}
        </label>
        <input
          v-model="login.email"
          type="email"
          class="custom-input custom-input-login w-full"
        />
        <label class="w-full custom-label-error" v-if="login.errorEmail">
          {{ login.errorEmail }}
        </label>
        <!-- password -->
        <label class="custom-label mt-8 w-full">
          <span class="asterisk">*</span>
          {{ $t("login.password") }}
        </label>
        <input
          v-model="login.password"
          type="password"
          class="custom-input custom-input-login w-full"
        />
        <label class="w-full custom-label-error" v-if="login.errorPassword">
          {{ login.errorPassword }}
        </label>
        <label
          class="w-full custom-label-error mt-5 text-center"
          v-if="login.errorLogin"
        >
          {{ login.errorLogin }}
        </label>
        <button type="submit" class="btn btn-primary mt-12" :disabled="loading">
          <span v-t="'login.submit'" v-if="!loading" />
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import LayoutHeader from "@/components/LayoutHeader";
import { auth } from "@/services/firebase";

export default {
  name: "Login",
  components: {
    LayoutHeader,
  },
  data() {
    return {
      loading: false,
      login: {
        email: "",
        password: "",
        errorEmail: "",
        errorPassword: "",
        errorLogin: "",
      },
    };
  },
  methods: {
    submitLogin: function () {
      if (!this.validateForm()) return;
      this.loading = true;
      auth
        .signInWithEmailAndPassword(this.login.email, this.login.password)
        .then((data) => {
          this.getInfoInstitution(data.user.uid);
        })
        .catch((err) => {
          this.login.errorLogin = "Usuario o contraseña incorrecto";
          console.error(err);
          this.loading = false;
        });
    },
    async getInfoInstitution(uuid) {
      await this.$store.dispatch("getInstitutionInfo", uuid);
      this.loading = false;
      if (this.$router.path != "/home") {
        this.$router.replace({ name: "Home" }).catch((err) => {
          console.log(err);
        });
      }
    },
    validEmail: function (email) {
      let re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateForm: function () {
      let isValidate = false;
      this.login.errorEmail = "";
      this.login.errorPassword = "";
      // empty form
      if (this.login.email === "" && this.login.password === "") {
        this.login.errorEmail = "Campo requerido";
        this.login.errorPassword = "Campo requerido";
      }
      // validate email
      if (!this.validEmail(this.login.email))
        this.login.errorEmail = "Email inválido";
      if (this.login.email === "") this.login.errorEmail = "Campo requerido";
      //validate password
      if (this.login.password === "")
        this.login.errorPassword = "Campo requerido";
      //sucess form
      if (this.validEmail(this.login.email) && this.login.password !== "")
        isValidate = true;

      return isValidate;
    },
  },
};
</script>
