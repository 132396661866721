<template>
  <section class="d-flex flex-column justify-center align-center">
    <div class="layout-header">
      <div class="layout-header__logos">
        <img
          class="layout-header__logos__palmira"
          src="@/assets/logo-palmira.svg"
        />
        <img
          class="layout-header__logos__palmira"
          src="@/assets/logo-ed-palmira.svg"
        />
      </div>
      <div
        class="layout-header__main layout-header__main--registration"
        :class="{ 'text-gray': isLogin, 'text-blue': !isLogin }"
      >
        <h1 class="layout-header__main__title">
          {{ $t("generic.title") }}
        </h1>
        <p class="layout-header__main__subtitle mt-2">
          {{ $t("generic.subtitle") }}
        </p>
      </div>
    </div>
    <hr
      class="layout-header__hr"
      :class="{ 'layout-header__hr--white': isLogin }"
    />
    <v-btn
      v-if="showLogout"
      @click="logout"
      class="ma-2 logout"
      text
      icon
      color="red"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { auth } from "@/services/firebase";
export default {
  name: "LayoutHeader",
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    showLogout: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    logout() {
      auth.signOut();
      localStorage.removeItem("registerPalmiras");
    },
  },
};
</script>
